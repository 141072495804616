
import './App.css';
import LandingPage from './components/LandingPage';


function App() {
  console.log('App component rendered')
  return (
    <div>
      <LandingPage/>
    </div>
);
}
export default App;
